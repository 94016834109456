.cta {
    display: flex;
    width: 100%;
    &.left_align {
      justify-content: start;
      button {
        margin-right: 1em;
      }
    }
    &.right_align {
      justify-content: end;
      button {
        margin-right: 1em;
      }
    }
  }
  
  .bonus-eligible {
    display: flex;
    justify-content: end;
    .form-group {
      margin-bottom: 0px !important;
      .form-check {
        margin: 10px 10px 0px 0px;
      }
    }
  }
  
  .form-group {
    .form-check {
      padding-left: 0;
      .form-check-label {
        margin-left: 5px;
      }
    }
  }
  .input-label {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding: 0px 4px;
    height: 8px;
    left: 10px;
    top: 0px;
    position: absolute;
    background: #ffffff;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 0px;
    .input-label-text {
      position: static;
      height: 16px;
      left: calc(50% - 211px / 2);
      top: -8px;
      font-size: 12px;
      line-height: 16px;
      display: flex;
      align-items: center;
      letter-spacing: 0.005em;
      color: #262626;
      flex: none;
      order: 0;
      flex-grow: 0;
      margin: 0px 0px;
      background: #ffffff;
    }
  }
  .input-outline {
    position: relative;
    left: 0 !important;
  }
  
  .users_col {
    border-left: solid 1px #e6eaea;
  }
  .form-control:disabled,
  .form-control[readonly] {
    background-color: rgba(26, 26, 26, 0.04);
  }
  .rbt-input-multi.form-control {
    min-height: 48px;
  }
  
  .rbt-input-multi {
    cursor: text;
    overflow: hidden;
    position: relative;
  }
  
  .rbt-input-multi .rbt-input-wrapper {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -4px;
    margin-top: -1px;
    overflow: hidden;
  }
  .rbt-token-removeable {
    cursor: pointer;
    padding-right: 21px;
  }
  
  .documentUpload-form {
    .input-content-outline {
      input {
        height: 48px;
      }
    }
  
    .react-datepicker-popper {
      z-index: 10 !important;
    }
  }
  
  .rbt-token {
    background-color: rgba(0, 63, 45, 0.15);
    border: 0;
    border-radius: 99rem;
    display: inline-block;
    line-height: 1em;
    font-size: 12px;
    display: flex;
    align-items: center;
    line-height: 1.2;
    margin: 5px;
    padding: 7px 30px 7px 12px;
    position: relative;
  
    button {
      background: rgba(38, 38, 38, 0.6);
      width: 20px;
      height: 20px;
      border-radius: 20px;
      min-width: 0px !important;
      margin: 0px 2px 0px 2px !important;
      font-size: 14px;
      position: absolute;
      right: 5px;
      span[aria-hidden='true'] {
        font-size: 24px;
        position: relative;
        top: -3px;
        font-weight: 500;
      }
  
      :hover {
        color: #ffffff;
      }
    }
  }
  
  .react-datepicker__input-container {
    button {
      right: 20px;
      &:after {
        background-color: rgba(38, 38, 38, 0.6);
      }
    }
  }
  
  .file-detail {
    margin: 15px 0px;
    padding-bottom: 5px;
  }
  
  .talent-coach {
    margin-top: 25px;
  }
  
  .react-datepicker {
    button {
      padding: 1px !important;
    }
  }
  
  table {
    width: 100%;
    white-space: nowrap;
    margin: 0;
    table-layout: fixed;
    margin-bottom: 0 !important;
    text-align: center;
  
    td {
      background: #fff;
      padding: 5px !important;
      text-align: center;
      white-space: pre-wrap;
      word-wrap: break-word;
    }
  
    th {
      padding: 5px !important;
      height: 40px;
      font-weight: 700 !important;
      white-space: pre-wrap;
    }
  
    tr {
      border-bottom: 1px solid #dee2e6;
    }
  }
  
  .upload-file-name-wrap {
      word-wrap: break-word;
  }
  