@import 'styles/colors.scss';


.redux-toastr div.crouton{
  background-color: $black;
  padding: 10px 20px 10px 20px;
  color: $white;
  font-size: 9px;
  border-radius: 3px;
  min-height: 20px;
  .rrt-left-container{
    display: none;
  }
  .rrt-middle-container{
    margin-left: 0px;
    padding: 0px;
  }
}

.redux-toastr div.top-center{
  width: 100px;
}