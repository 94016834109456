@import 'styles/colors.scss';
@import 'styles/media.scss';
@import 'styles/mixins.scss';

nav.header-nav--top {
  z-index: 1;
}
.header-nav--menu-undocked {
  .header-nav__toggle {
    &.navbar-toggler {
      display: block;
      padding: revert;
    }
  }
}
.header-nav-actions-navbar {
  z-index: 1;
  cursor: pointer;
}
.header-nav {
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  background-color: $primary;
  border-bottom: 1px solid #cad0d8;
  height: 44px;
  color: #fff;
  a {
    color: #fff;
    &:hover {
      color: #fff;
    }
  }
  @include media-breakpoint-up(lg) {
    height: 68px;
  }
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__toggle {
    display: block;
    > .navbar-toggler-icon {
      background-image: url(../../../../assets/icons/burger-white.svg) !important;
      height: 24px;
      width: 24px;
    }
    > span {
      border-color: $white !important;
    }
  }
  &__actions-go-home {
    @include smScreen {
      width: 24px;
      height: 24px;
      margin-right: 12px;
      &:last-child {
        margin-right: 20px;
      }
    }
  }
  &__logo {
    position: relative;
    left: 12px; // adjustment to center align in flex box context
    @include media-breakpoint-up(sm) {
      flex-grow: 1;
      left: 0px;
    }
    &-icon {
      height: auto;
    }
  }
  &__actions {
    display: flex;
    justify-content: flex-end;
    &-go-home {
      display: block;
      width: 24px;
      text-align: center;
      height: 24px;
      background-size: cover !important; //for IE browser added important
      margin-right: 12px;
      > span {
        display: none;
      }
      &:last-child {
        margin-right: 52px;
        @include smScreen {
          margin-right: 12px;
        }
      }
    }
    &-app-online {
      display: block;
      width: 40px;
      height: 24px;
      background: url(../../../../assets/icons/onlineCloud.svg) no-repeat center center;
    }
    &-app-offline {
      display: block;
      width: 40px;
      height: 24px;
      background: url(../../../../assets/icons/offlineCloud.svg) no-repeat center center;
    }
  }
}

// notification bell. css moved out of notificationBell.scss until it is refactored
// allows higher specicifity selectors to over rule reactstrap classes
.header-nav {
  .notificationBell-dropdown {
    width: 24px;
    height: 24px;
    position: relative;
    overflow: visible;
    margin-right: 15px;
    @include media-breakpoint-up(sm) {
      margin-right: 14px;
    }
    .notificationBell-dropdown-toggle.btn {
      width: 24px;
      height: 24px;
      background-color: transparent;
      border: 0;
      padding: 0;
      margin: 0;
      &:focus {
        box-shadow: none;
      }
    }
    .header-nav__bell {
      width: 24px;
      height: 24px;
      position: absolute;
      top: 1px;
      left: 0;
      z-index: 1;
      background-color: transparent;
    }
    .quantity {
      display: block;
      position: absolute;
      top: -2px;
      left: 14px;
      z-index: 2;
      line-height: 1;
      border-radius: 1rem;
      padding: 2px 5px;
      font-size: 12px;
      > span {
        position: relative;
        top: 1px;
      }
    }

    .quantity-yellow {
      color: $black;
      font-weight: bold;
      background-color: $yellow;
    }

    .quantity-grey {
      color: white;
      background-color: #333;
    }
  }
}

#sidebarCollapse {
  margin-top: -6px;
  margin-left: -10px;
  margin-right: 10px;
  width: 30px;
  height: 30px;
  min-width: auto;
  border: none;
  display: none;
  padding: 0 6px;
  border-radius: 0;
}
@media only screen and (max-width: 767px) {
  #sidebarCollapse {
    display: block;
    cursor: pointer;
  }
}
#sidebarCollapse span {
  margin: 0 auto;
  display: block;
  -webkit-transition: all 0.8s cubic-bezier(0.81, -0.33, 0.345, 1.375);
  -moz-transition: all 0.8s cubic-bezier(0.81, -0.33, 0.345, 1.375);
  -ms-transition: all 0.8s cubic-bezier(0.81, -0.33, 0.345, 1.375);
  -o-transition: all 0.8s cubic-bezier(0.81, -0.33, 0.345, 1.375);
  transition: all 0.8s cubic-bezier(0.81, -0.33, 0.345, 1.375);
}

@include smScreen {
  #sidebarCollapse span:first-of-type,
  #sidebarCollapse span:nth-of-type(2),
  #sidebarCollapse span:last-of-type {
    transform: none;
    opacity: 1;
    margin: 5px auto;
  }
  #sm_none {
    display: none;
  }
}
.project_name_title {
  label {
    color: #f4f6fb;
    float: left;
    font-size: 16px;
    text-transform: capitalize;
    font-weight: 500;
    margin: 0 0 0 36px;
    line-height: 18px;
  }
  span {
    color: #f4f6fb;
    float: left;
    font-size: 10px;
    text-transform: capitalize;
    font-weight: 500;
    margin: 0 0 0 10px;
    line-height: 18px;
  }
}

.burgerMenu {
  position: relative;
  margin-left: 56px;
  margin-right: 20px;
  @include smScreen {
    margin-left: 15px;
  }
  .icon_menu {
    font-size: 20px;
    cursor: pointer;
  }
  .submenu {
    position: absolute;
    background: #fff;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
    z-index: 10;
    ul {
      margin: 0;
      padding: 0;
      li {
        list-style: none;
        a {
          display: flex;
          padding: 0px 16px;
          align-items: center;
          min-width: 250px;
          height: 52px;
          color: #262626;
          font-size: 16px;
          &:hover {
            text-decoration: none;
            background: rgba(0, 0, 0, 0.08);
          }
        }
      }
      &.anotherGroup {
        border-top: solid 1px rgba(0, 63, 45, 0.1);
        .groupTitle {
          color: rgba(38, 38, 38, 0.6);
          font-size: 16px;
          letter-spacing: -0.01em;
          padding: 10px 9px;
          margin: 0;
          border-top: 1px solid lightgray;
          font-weight: bold;
        }
      }
    }
  }
}
