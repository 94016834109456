@import 'styles/colors.scss';
@import 'styles/media.scss';
@import 'styles/mixins.scss';

.custom-wrap2 {
  padding: 15px 25px 20px 25px;
  background: #fff;
  margin-right: 41px;
  border-radius: 4px;
  margin-bottom: 30px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.14);
  @include lgScreen {
    margin-right: 0px;
  }
  @include smScreen {
    padding: 15px 25 15px 25px;
    margin-left: 0;
  }
}

.col-md-3 + .col {
  .custom-wrap2 {
    margin-right: 41px;
  }
}

.col {
  .custom-wrap2 {
    margin-right: 0px;
  }
}

button[type='button'].icoBtn {
  background: $white;
  padding: 5px 10px;
  min-width: 40px;
  margin: auto 5px;
  border-color: $dkGray1;
}
button[type='button'].icoBtn:disabled {
  cursor: auto;
}
button[type='button'].icoBtn svg {
  color: $dkGray1;
  vertical-align: middle;
}
button[type='button'].icoBtn:hover:enabled svg {
  color: $black;
  vertical-align: middle;
}

.paginationButton {
  font-size: 8pt;
  border: 0;
  background-color: none;
}

.formsListItem:hover {
  background-color: $ltGray3;
}

.left-col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  /* Background/Surface */

  background: #ffffff;
  /* Elevation/Elevation 2 dp */

  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.14);
  border-radius: 4px;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 24px;
}
.sorting_select {
  font-size: 14px;
  padding: 5px 7px;
  border-radius: 4px;
  max-width: 200px;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.showing-title {
  font-size: 20px;
  line-height: 24px;
}
