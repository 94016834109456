@import 'styles/mixins.scss';
@import 'styles/colors.scss';

.file-uploader {
  width: 100%;
  border: 2px dashed #262626;
  border-radius: 5px;
  padding: 30px 10px;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: calc(100% - 10px);
}

.file-uploader--dragging {
  background-color: lightblue;
}

.file-uploader__contents {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.file-uploader__file-name {
  font-weight: 700;
}
.jumbotron {
  background-color: #fff !important;
}
.noBorder td {
  border: none !important;
}
.t6R15P {
  right: 15%;
  top: 6%;
  margin-bottom: 1%;
}
.lft2 {
  left: 2%;
}
.btnminwdth {
  padding: 6px 6px 7px !important;
  min-width: 75px !important;
}
.mrgnT5Prc {
  margin-top: 4%;
}
.wd25 {
  width: 25% !important;
}
.success {
  background: $lightGreen;
}

.blue {
  color: blue;
}
.blue:hover {
  color: yellowgreen !important;
}

.hide {
  display: none !important;
}
.show {
  display: block !important;
}

.file-upload {
  color: #dfdfdf;
  width: 40px !important;
  height: 52px !important;
}

.drag_drop {
  color: $grey005;
  margin-top: 15px;
}
.drag_drop_or {
  line-height: 1;
  padding: 10px;
}

.grey_color,
.grey_color:hover {
  color: #cad0d8 !important;
}

.file-upload-loader {
  text-align: center;
}

.edit-btn {
  background-color: #fff;
}
