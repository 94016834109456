/*pipeline page*/
.top_Title {
  background: #fff;
  padding: 5px 20px 5px 15px;
  border-bottom: solid 1px #cad0d8;
  border-radius: 5px 5px 0 0;
  position: relative;
  margin-top: 30px;
  margin-bottom: 15px;
  @include mdScreen {
    padding: 6px 20px 5px 15px;
    border: 0;
  }
  .excel_icon[type='button'] {
    border: 0;
    padding: 0;
  }

  h2 {
    color: #006a4d;
    font-size: 13px;
    font-weight: 600;
    text-transform: capitalize;
    margin: 5px 0;
    position: relative;
    padding-left: 12px;
    line-height: 24px;

    &:before {
      content: '';
      position: absolute;
      height: 24px;
      width: 2px;
      background: #006a4d;
      left: 0px;

      @include mdScreen {
        width: 0;
      }
    }

    @include mdScreen {
      margin-bottom: 0;
      font-size: 13px;
    }
  }

  span {
    button {
      padding: 6px 25px 3px;
      min-width: auto;
      background: transparent;
      color: #006a4d;
      border-radius: 5px;
    }
  }
}

/*pipeline grid*/
/*
 * Pricing Summary
 */
.pipeline_grid {
  width: 100%;
  margin: 18px 0 30px;
  @include mdScreen {
    margin: 0 0;
  }
  .inner-block {
    @include mdScreen {
      overflow-x: auto;
    }
  }
  label {
    color: map-get($colors, grey03);
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 600;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    overflow-x: scroll;
    margin-bottom: 15px;
    th {
      font-size: 14px;
      font-weight: 700;
      padding: 5px 5px 5px 5px;
      vertical-align: middle !important;
    }
    td {
      padding: 5px 5px 5px 5px;
      border: solid 1px map-get($colors, grey06);
      color: map-get($colors, grey03);
      font-size: 14px;
    }
    &.price-table {
      margin-bottom: 15px;
      @include mdScreen {
        margin-bottom: 0;
      }
      th {
        color: map-get($colors, grey03);
        position: sticky;
        top: -1px;
        z-index: 1;
        text-transform: capitalize;
      }
      td {
        background-color: transparent;
        span {
          padding: 0 3px 0 1px;
        }
        &:first-child {
          font-weight: 400;
        }
        a {
          color: map-get($colors, Green04);
          text-decoration: none;
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
    &.cost-table {
      th {
        color: map-get($colors, darkGreen);
        font-weight: 700;
      }
      td {
        background-color: map-get($colors, lightGreen03);
        font-weight: 600;
      }
    }
  }
}

.table-grid-wrap,
.pipeline_grid {
  overflow-x: auto;
  table {
    .price-table {
      td {
        background-color: map-get($colors, whiteColor);
      }
    }
  }
  th {
    border: solid 1px #cad0d8;
    background: map-get($colors, whiteColor);
    position: static;
    div {
      @include transition(all ease 0.3s);
      position: relative;
      padding-left: 5px;
      display: inline-block;
      height: 100%;
      top: auto;
      float: right;
    }
    svg {
      position: absolute;
      right: -8px;
      top: 2px;
      &:before {
        top: 38%;
        position: relative;
      }
      &.active {
        color: map-get($colors, darkGreen);
        @include transform(rotate(-180deg));
      }
    }
  }
  tbody {
    tr {
      &:nth-of-type(odd) {
        background-color: map-get($colors, grey11);
        @include mdScreen {
          background-color: map-get($colors, whiteColor);
        }
      }
      &:nth-of-type(even) {
        background-color: map-get($colors, whiteColor);
      }
    }
  }
  &.price-sumry {
    margin-top: 0;
    .price-table {
      td {
        background-color: transparent;
        &:first-child {
          font-weight: 400;
        }
      }
    }
    table {
      th {
        padding: 5px 22px 5px 15px;
        border-top: 0;
        min-width: 110px;
      }
    }
  }
}
/*pipeline filters styling*/
.filters_outer {
  //background: #fff;
  margin-bottom: 30px;
  border-radius: 5px;
  position: relative;
  .top_Title {
    border-bottom: 0;
  }
  h2 {
    padding-top: 4px;
  }
  span {
    button,
    a {
      text-decoration: none;
      &.active {
        background: #69be28;
        color: #fff;
        border-color: #69be28;
      }
      background: #006a4d;
      color: #fff;
      border-color: #006a4d;
      font-size: 16px;
      font-weight: 600;
      margin-right: 0;
      margin-left: 15px;
      padding: 6px 15px 3px;
      transition: background-color 0.3s ease;
      min-width: auto;
      span {
        text-transform: uppercase;
      }
      i {
        padding-left: 10px;
        @include mdScreen {
          padding-left: 0;
        }
      }
      .hover {
        display: none;
      }
      .before_hover,
      .hover {
        max-width: 22px;
        margin-left: 12px;
        margin-top: -2px;
      }
      &:hover {
        .before_hover {
          display: none;
        }
        .hover {
          display: inline-block;
        }
      }
    }
  }
  .filters_inner {
    background: #fbfbfb;
    border: 1px solid #e8ecf3;
    border-radius: 5px;
    margin-top: 8px;
    position: relative;
    &.active {
      display: block;
    }
    &.deactive {
      display: none;
    }
    &:before,
    &:after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      border-color: transparent;
      border-top: 0;
    }
    &:before {
      border-bottom-color: #e8ecf3;
      top: -10px;
      right: 68px;
      border-width: 10px;
      border-right-width: 9px;
      border-left-width: 10px;
      @include mdScreen {
        right: 35px;
      }
    }
    &:after {
      top: -8px;
      right: 69px;
      display: inline-block;
      border-bottom-color: #fbfbfb;
      border-width: 8px;
      border-right-width: 8px;
      border-left-width: 8px;
      @include mdScreen {
        right: 36px;
      }
    }
    .inner_content {
      padding: 30px 30px 30px 30px;
      height: 100%;
      @include lgScreen {
        padding: 20px 20px 20px 20px;
      }
      @include mdScreen {
        border-right: 0;
      }
      @include smScreen {
        padding: 15px 15px 15px 15px;
      }
      .form-group {
        &:last-child {
          margin-bottom: 0;
        }
      }
      .select-wrapper {
        &:before {
          right: 10px;
        }
      }
      select {
        &.form-control {
          max-width: 100%;
        }
      }
    }
    .col-lg-3 {
      &:last-child {
        .inner_content {
          border-right: 0;
        }
      }
    }
    .two-side-btn {
      padding-right: 20px;
      @include smScreen {
        padding-right: 7px;
      }
      @include xsScreen {
        padding-right: 0;
      }
      button {
        &:nth-child(2) {
          @include mdScreen {
            margin-right: 0;
          }
        }
      }
    }
    .range-date {
      .cal_icon {
        margin-bottom: 10px;
        display: block;
      }
    }
    .cal_icon {
      position: relative;
      .form-control {
        padding-right: 0px;
        margin-right: 0;
        padding-left: 0;
        text-align: center;
        width: 30%;
        @include mdScreen {
          width: 32%;
        }
        &:nth-child(1) {
          border-radius: 5px 0px 0px 5px;
        }
        &:nth-child(2) {
          border-radius: 0;
          border-left: 0;
          border-right: 0;
        }
        &:nth-child(3) {
          border-radius: 0 5px 5px 0;
        }
      }
      img {
        position: relative;
        right: -10px;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }
  }
}

.custom-size-filter {
  &.form_style {
    padding: 15px 0;
    .select-wrapper {
      &:before {
        border: 2px solid map-get($colors, darkGreen);
        border-left: 0;
        border-top: 0;
        right: calc(100% - 47px);
      }
      select {
        &.form-control {
          background: map-get($colors, whiteColor);
          border: 2px solid map-get($colors, darkGreen);
          color: map-get($colors, darkGreen);
          font-weight: 600;
          padding: 9px 30px 9px 15px;
          border-radius: 5px;
          font-size: 14px;
          line-height: 19px;
        }
      }
    }
  }
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  /* IE10+ specific styles go here */
  .custom-size-filter {
    &.form_style {
      .select-wrapper {
        &:before {
          right: calc(100% - 50px);
          top: 14px;
        }
        select {
          &.form-control {
            height: 39px !important;
          }
        }
      }
    }
  }
}
