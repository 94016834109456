.input-outline {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  height: auto;
  border: 1px solid #262626;
  box-sizing: border-box;
  border-radius: 4px;
  align-self: stretch;
  flex-grow: 0;
  margin: 0px 0px;
}

.input-content-outline {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  height: auto;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 0px 0px;

  select {
    border: none;
    height: 48px;
    &:focus {
      outline: none;
      border: none;
      box-shadow: none;
    }
  }

  textarea {
    border: none;
    &:focus {
      outline: none;
      border: none;
      box-shadow: none;
    }
  }
}

.input-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: static;
  height: auto;
  left: 0px;
  top: 0px;
  border: none;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 1;
  max-width: 100%;

  .react-datepicker-wrapper {
    width: 100%;
    input[type='text'] {
      height: 48px;
    }
  }

  input {
    border: none;
    padding: 10px;
    &:focus {
      outline: none;
      border: none;
      box-shadow: none;
    }
  }

  .rbt-input-multi.rbt-input {
    border: none;
    &:focus {
      outline: none;
      border: none;
      box-shadow: none;
    }
    .rbt-input-wrapper {
      width: calc(100% - 25px);
    }
  }
}

.input-label {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 0px 4px;
  height: 8px;
  left: 10px;
  top: 0px;
  position: absolute;
  z-index: 9;
  background: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px;
}

.input-label-text {
  position: static;
  height: 16px;
  left: calc(50% - 211px / 2);
  top: -8px;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.005em;
  color: #262626;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px;
  background: #ffffff;
}

.parent-width {
  width: 100%;
}
.select-dropdown {
  position: relative;
  background-color: #fff;
  width: auto;
  float: left;
  max-width: 100%;
  border-radius: 2px;
  select {
    font-family: 'helvetica neue', helvetica;
    font-size: 1rem;
    font-weight: 200;
    max-width: 100%;
    padding: 8px 24px 8px 10px;
    border: none;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    &:active,
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
  &:after {
    content: ' ';
    position: absolute;
    top: 50%;
    margin-top: -2px;
    right: 20px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #171c1b;
  }
}

.form-control.rbt-input-multi.rbt-input[disabled] {
  background-color: rgba(26, 26, 26, 0.04);
}

.rbt-input-hint-container + .placehold {
  position: absolute;
  right: 15px;
  top: calc(100% - 35px);
}
.rbt-input-hint-container > input {
  height: 48px;
}
.rbt-input-hint > input {
  height: 29px;
}
