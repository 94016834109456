@import 'styles/media.scss';
@import 'styles/mixins.scss';

$darkGreen: #003f2d;
body {
  background-color: #e5e5e5 !important;
}
.default-layout-wrapper {
  position: relative;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  &--fluid {
    padding-right: 0px;
    padding-left: 0px;
  }
}

.wrapper {
  display: flex;
  align-items: stretch;
}

.headerMargin {
  margin: 0 0 0 auto;
}
#content {
  width: calc(100% - 250px);
  min-height: 100vh;
  margin-left: 250px;
  @include transition(all 0.3s);
  @media only screen and (max-width: 767px) {
    width: 100%;
    margin-left: 0;
  }
  @include smScreen {
    width: 100%;
  }
}

.buttonHover {
  border: 2px solid $darkGreen;
  &:hover,
  &.active {
    color: $darkGreen;
    border: 2px solid $darkGreen;
    background-color: transparent;
  }
}

.two-side-btn {
  padding: 40px 0;
  display: flex;
  justify-content: space-between;
  @include mdScreen {
    flex-wrap: wrap;
    justify-content: center;
    padding: 20px 0;
  }
  button {
    margin-right: 20px;
    @include mdScreen {
      margin: 8px 8px;
    }
    &:nth-child(2) {
      margin-right: 0;
      @include mdScreen {
        margin-right: 8px;
      }
    }
    @include xsScreen {
      width: 100%;
      margin-left: 20px !important;
      margin-right: 20px !important;
    }
  }
}

.two-side-btn-top {
  padding: 15px 0;
  display: flex;
  justify-content: space-between;
  @include mdScreen {
    flex-wrap: wrap;
    justify-content: center;
    padding: 20px 0;
  }
  button {
    margin-right: 20px;
    @include mdScreen {
      margin: 8px 8px;
    }
    &:nth-child(2) {
      margin-right: 0;
      @include mdScreen {
        margin-right: 8px;
      }
    }
    @include xsScreen {
      width: 100%;
      margin-left: 20px !important;
      margin-right: 20px !important;
    }
  }
}
.conductAssessment-form .indicator-card .body {
  margin-bottom: 0 !important;
}

form,
.form_style {
  label {
    color: $grey03;
    margin-bottom: 13px;
    font-size: 13px;
  }

  .form-group {
    margin-bottom: 25px;
    position: relative;

    mark {
      padding: 0;
    }

    .symbol_fix {
      position: absolute;
      left: 15px;
      top: 43px;
      color: $grey06;
      font-size: 14px;
      margin-right: 10px;
    }

    .placehold {
      position: absolute;
      right: 15px;
      top: calc(100% - 35px);
    }

    .datepicker-placehold {
      position: absolute;
      right: 12px;
      top: 12px;
      color: rgba(38, 38, 38, 0.6);
    }
  }

  .form-control {
    @include borderRadius(5px);
    border: 1px solid $grey05;
    height: auto;
    padding: 5px;
    font-size: 13px;
    font-weight: 500;
    text-transform: initial;
    color: $grey002;

    &:focus {
      @include boxShadow(1px 0px 7px rgba(170, 169, 169, 0.2));
      border: 1px solid #e8ecf3;
    }

    &.custom-space {
      padding: 12px 10px 8px 30px;
    }
  }

  textarea {
    resize: none;
  }

  .priority {
    .select-wrapper {
      &:before {
        right: calc(100% - 270px) !important;

        @include smScreen {
          right: 10px !important;
        }
      }
    }
    label {
      font-size: 12px !important;
    }
  }

  .select-wrapper {
    position: relative;
    &::-ms-expand {
      display: none;
    }

    &.header_select_wrapper {
      margin-top: 5px;

      &:before {
        right: calc(100% - 169px);
      }
    }

    select {
      &.form-control {
        text-transform: capitalize;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: 1px solid $grey08;
        position: relative;
        @include borderRadius(3px);
        padding: 10px 30px 10px 10px;
        height: auto !important;

        @include smScreen {
          max-width: 100%;
        }
      }
    }

    &:before {
      content: '';
      position: absolute;
      z-index: 9;
      background-repeat: no-repeat;
      background-position: center right;
      width: 8px;
      height: 8px;
      right: 4%;
      top: 14px;
      margin: auto 0;
      border: 2px solid #006a4d;
      border-top: 0;
      border-left: 0;
      @include transform(rotate(45deg));
      @include transition(all 0.3s);
    }

    &.active {
      &:before {
        @include transform(rotate(225deg));
        top: 18px;
      }
    }
  }
  .form-check {
    position: relative;
    padding-left: 0;

    // input[type='radio'] {
    //     opacity: 0;
    // }

    label {
      padding-left: 10px;
      color: $grey03;
      margin-bottom: 7px !important;
      font-weight: 500;

      // &:before {
      //     width: 15px;
      //     height: 15px;
      //     position: absolute;
      //     top: 4px;
      //     left: 0;
      //     content: '';
      //     display: inline-block;
      //     @include borderRadius(50px);
      //     border: 1px solid $grey03;
      //     background: transparent;
      //     z-index: -1;
      // }
    }

    input[type='radio'] {
      margin: 5px 0 0 0px;
      &:checked + label {
        color: $black;

        // &:after {
        //     content: '';
        //     position: absolute;
        //     top: 8px;
        //     left: 4px;
        //     display: inline-block;
        //     font-size: 11px;
        //     width: 7px;
        //     height: 7px;
        //     background-color: $lightGreen02;
        //     @include borderRadius(50px);
        // }

        // &:before {
        //     border: 1px solid $lightGreen02;
        // }
      }
    }

    label.error {
      &:before {
        border: 1px solid #e13e3e !important;
      }
    }
  }
}

ol.categories {
  color: #818a8f;
  font-size: 13px;
  font-weight: 500;
}

.text-danger {
  font-size: 12px;
  font-style: italic;
  font-weight: 300;
  position: relative;
}

.custom_modal {
  background: rgba(0, 0, 0, 0.5);
  &.modal {
    &.show {
      .modal-dialog {
        max-width: 400px;
        margin: 1.75rem auto;
        padding: 10px;
        width: 100%;
      }
      .modal-content {
        border: 0;
        padding: 20px;
        p {
          margin-bottom: 10px;
          font-size: 16px;
          text-align: center;
        }
        .modal-header {
          padding: 2px;
          border: 0;
        }
        .modal-title {
          color: $grey03;
          font-weight: 700;
          margin-bottom: 0;
          font-size: 20px;
        }
        .modal-body {
          padding: 0;
          font-size: 11px;
          color: $grey03;
          font-weight: 400;
          width: 100%;
        }
      }
      .modal-footer {
        border: 0;
        padding: 15px 0 0 0;
        display: flex;
        justify-content: space-between;
        button {
          min-width: 120px;
        }
      }
      .close {
        position: absolute;
        right: 0px;
        top: -2px;
        color: #818a8f;
        font-weight: 400;
        cursor: pointer;
      }
    }
  }
}

.modal_width {
  max-width: 1600px !important;
  width: 96% !important;
  overflow-y: initial !important;
}

.modal_body_scroll {
  height: 85vh;
  overflow-x: hidden;
}

.modal-content-padding {
  padding: 5px !important;
}
/*topbar*/

.logo {
  padding: 14px 0;
  cursor: pointer;
  img {
    max-width: 156px;
  }
}

.topbar {
  background: $darkGreen;
  height: 56px;
  display: flex;
  align-items: center;
  .logo {
    display: none;
    float: left;
    cursor: pointer;
    padding: 0px;
    @include smScreen {
      display: block;
      padding: 0px;
      img {
        max-width: auto;
      }
    }
  }

  .logoText {
    display: none;
    float: left;
    cursor: pointer;
    padding: 0px 0 0px 10px;
    margin-top: 4px;
    font-size: 24px;
    @include smScreen {
      display: block;
      font-size: 12px;
      max-width: 140px;
    }
  }

  .icons-blocks {
    list-style: none;
    margin: 0;
    align-items: center;
    display: flex;
    height: 60px;
    margin-left: auto;
  }
}
.breadcrumbSC {
  padding: 0px 56px 0 56px;
  background-color: #fff;
  margin-bottom: 20px;
  height: 76px;
  display: flex;
  align-items: center;
  h1 {
    font-size: 34px;
    line-height: 40px;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.01em;
    color: #171c1b;
    margin: 0;
    padding: 0;
    font-weight: normal;
    padding-left: 41px !important;
    @include smScreen {
      padding-left: 0px !important;
    }
  }
}

.custom-wrap {
  padding: 15px 25px 20px 25px;
  margin-left: 41px;
  background: #fff;
  border-radius: 4px;
  margin-bottom: 30px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.14);
  @include lgScreen {
    margin-left: 0px;
  }
  @include smScreen {
    padding: 15px 25 15px 25px;
    margin-left: 0;
  }
  &.wd-100 {
    margin-left: 0;
  }
  h2 {
    font-size: 26px;
    font-weight: 500;
    color: $darkBlack;
    margin-bottom: 15px;
  }
  .card {
    border: 0 !important;
  }
  h1 {
    margin-bottom: 40px;
    margin-top: 15px;
    font-size: 32px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: $grey07;
    @include mdScreen {
      margin-bottom: 30px;
      margin-top: 20px;
      font-size: 28px;
    }
    @include smScreen {
      margin-bottom: 20px;
      margin-top: 10px;
      font-size: 24px;
    }
  }
}

.cursorPntr {
  cursor: pointer !important;
}

::-webkit-scrollbar-track {
  // box-shadow: inset 0 0 6px rgba(0,0,0,0.0);
  // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.0);
  background-color: #c2c2c1 !important;
  height: 4px !important;
}

::-webkit-scrollbar {
  width: 6px !important;
  background-color: #dfdfdf !important;
  height: 6px !important;
}

::-webkit-scrollbar-thumb {
  background-color: #818a8f !important;
  width: 4px !important;
  height: 4px !important;
}

.sbl-circ-path {
  color: $lightGreen;
  width: 30px !important;
  height: 17px !important;
  animation: 550ms !important;
  right: 30px !important;
  bottom: 8px !important;
  padding-right: 6px;
  svg {
    color: $lightGreen02;
  }
}

.table-row-no-pending-actions {
  text-align: center;
  display: block;
  padding: 11px 0 10px 0;
  background: map-get($colors, whiteColor);
  svg {
    margin-right: 5px;
  }
}

button[type='submit'],
button[type='button'] {
  font-weight: 600;
  padding: 12px 20px 10px;
  font-size: 16px;
  text-align: center;
  letter-spacing: initial;
  @include borderRadius(5px);
  cursor: pointer;
  &:hover,
  &.active {
    color: map-get($colors, darkGreen);
    background-color: transparent;
  }
  @include mdScreen {
    padding: 10px 25px;
  }
  @include smScreen {
    padding: 8px 15px;
    font-size: 14px;
  }
}

.button-border {
  border: 2px solid map-get($colors, darkGreen) !important;
}

.total-records {
  left: 0;
  position: absolute;
  margin-left: 4%;
}

.regular-fw {
  font-weight: 600 !important;
}

.medium-fw {
  font-weight: 700 !important;
}

.semi-bold-fw {
  font-weight: 800 !important;
}

.red {
  color: orangered;
}
.yellow {
  color: gold;
}
.green {
  color: limegreen;
}

.dot {
  box-sizing: border-box;
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 50%;
  border: 0.125rem solid;
  display: inline-block;
  background-color: currentColor;
}
.minwidth{
  min-width: 800px !important;
}
.textAlignCenter{
  text-align: center;
}
.displayNoData{
  width: 100%;
  font-size: 16px;
}
.auditPopup {
  .custom_modal.modal.show {
    .modal-dialog {
      max-width: 80% !important;
      height: 100%;
      margin: 0 0 0 10% !important;

      .modal-content {
        height: 95%;
        overflow-y: scroll;
      }

      .close {
        top: -8px !important;
        font-size: 40px;
      }
    }
  }
}

.auditPopup{
  .table{
      white-space: nowrap;
      margin: 0;
      border: none;
      border-collapse: separate;
      border-spacing: 0;
      table-layout: fixed;
      margin-bottom: 0 !important;
      text-align: center;
  }
}

.auditPopup table tbody td {
  word-wrap: break-word;
  white-space: break-spaces;
}
.auditPopup table td {
  background: #fff;
  padding: 5px !important;
  text-align: center;
}
.auditPopup table th {
  border-top: none !important;
  padding: 5px !important;
  height: 40px;
  font-weight: 700 !important;
  white-space: pre-wrap;
  text-align: center;
}

.rich-text-field {
  flex-grow: 1;
  word-break: break-word;
}

.ql-editor{
  min-height:200px;
}

input.right-input {
  text-align: end;
}

.fieldPrefix {
  display: inline-flex;
  align-items: center;
  padding: 0 5px 0 10px;
  color: rgba(26, 26, 26, 0.6);
  height: 100%;
  font-size: 14px;
}

.fieldSuffix {
  display: inline-flex;
  align-items: center;
  padding: 0 5px 0 0px;
  color: rgba(26, 26, 26, 0.6);
  height: 100%;
  font-size: 14px;
}