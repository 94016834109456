$green1: #00684d;
$green2: #004231;
$black: #171c1b;
$medGreen: #4cbe7f;
$lightGreen: #00ad4d;
$darkGreen: #006a4d;
$white: #fff;
$lime4: #cef1a3;
$dkGray1: #526061;
$medGray1: #a9b2b5;
$medGray2: #cad0d8;
$ltGray3: #f4f6fb;
$ltGray4: #757575;
$darkBlack: #231f20;
$yellow: #fbd55b;
$orange: #ea8032;
$purple: #935fd6;
$blue1: #3daae9;

// New Design colors
$watercourse: #003f2d;
$greenHaze: #00ad4d;
$cinnabar: #e13e3e;
$mineShaft: #333333;
$whiteLilac: #f4f6fb;
$whiteLilac1: #f5f7fc;
$jaffa: #ea8032;
$gray: #929292;
$athensGray: #e5e5ea;
$blackNew: #030303;
$mountainMist: #8f8e94;

$action: $greenHaze;
$inactive: $athensGray;
$primary: $watercourse;
$red: $cinnabar;
$text: $mineShaft;
$background: $whiteLilac;
$backgroundAlt: $white;
$orange: $jaffa;
$placeholder: $gray;
$divideMinor: $mountainMist;
$divideMajor: $gray;
$borderBottom: #d8d8d8;

/*
Colours for Site Nav
*/

$secondary-dark-grey: #526061;
$primary-light-green: $action;

/*
Shades of Grey
*/
$grey01: #272728;
$grey02: #393939;
$grey03: #526061;
$grey04: #f4f6fb;
$grey05: #e8ecf3;
$grey06: #cad0d8;
$grey07: #171c1b;
$grey08: #eaeef5;
$grey09: #b1b9bc;
$grey10: #667374;
$grey12: #bbc3c5;
$grey13: #f5f5f5;
$grey14: #dfdfdf;
$grey001: #dfdfdf;
$grey002: #818a8f;
$grey003: #f5f6fb;
$grey004: #333333;
$grey005: #c2c2c1;

/*
Shades of Green
*/
$lightGreen: #6dd400;
$lightGreen02: #4cbe7f;
$lightGreen03: #41ba77;
$Green03: #cef2a3;
$Green04: #69be28;

$colors: (
  whiteColor: #ffffff,
  lightenBgColor: #fbfaf8,
  lightgrey: #eeeeee,
  grey01: #272728,
  grey02: #393939,
  grey03: #526061,
  grey04: #f4f6fb,
  grey05: #e8ecf3,
  grey06: #cad0d8,
  grey07: #171c1b,
  grey08: #eaeef5,
  grey09: #b1b9bc,
  grey10: #667374,
  grey12: #bbc3c5,
  grey13: #f5f5f5,
  grey14: #dfdfdf,
  darkGreen: #006a4d,
  darkGreen02: #00513b,
  red: #e13e3e,
  lightGreen: #6dd400,
  lightGreen02: #4cbe7f,
  lightGreen03: #41ba77,
  Green03: #cef2a3,
  Green04: #69be28,
  limeGreen: #69c029,
  black: #000000,
  grey11: #fbfcff,
  /*coil colors*/ primaryGreen: #006a4d,
  grey001: #dfdfdf,
  darkBlack: #231f20,
  grey002: #818a8f,
  grey003: #f5f6fb,
  grey004: #333333,
  grey005: #c2c2c1,
  orange: #f58220
);
