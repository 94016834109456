@import './colors.scss';

@mixin button {
  padding: 10px 20px 8px 20px;
  border-radius: 20px;
  font-weight: bold;
  border: none;
  cursor: pointer;
  display: block;
  text-align: center;
  &:hover{
    text-decoration: none;
  }
  &:focus {
    outline: 1px dotted black;
  }
}

@mixin button-primary {
  @include button;
  background-color: $greenHaze;
  color: $white;
  &:hover{
    background-color: $watercourse;
    color: $white;
  }
}
@mixin button-primary-selected {
  @include button;
  background-color: $white;
  color: $black;
  padding: 8px 18px 6px 18px;
  border: solid 3px $action;
  font-weight: bold;
  &:hover{
    border: solid 3px $action;
    background-color: $white;
    color: $black;
  }
}
@mixin button-disabled {
  @include button;
  background: $placeholder;
  color: $white;
  &:hover {
    background: $placeholder;
    color: $white;
    cursor: not-allowed;
    border: none;
  }
}

@mixin button-secondary {
  @include button;
  background-color: $white;
  color: $black;
  border: solid 1px $athensGray;
  font-weight: normal;
  &:hover{
    border: solid 1px $action;
  }
}
@mixin button-secondary-selected {
  @include button;
  background-color: $white;
  color: $black;
  padding: 8px 18px 6px 18px;
  border: solid 3px $action;
  font-weight: bold;
  &:hover{
    border: solid 3px $action;
  }
}

@mixin button-secondary-icon {
  @include button-secondary;
  background-color: $white;
  background-repeat: no-repeat;
  background-size: 23px;
  background-position: 22px 10px;
  color: $black;
  border: solid 1px $athensGray;
  font-weight: normal;  
  &:hover{
    color: $black;
    background-color: $white;
  }
}
@mixin button-secondary-icon-selected {
  @include button-secondary-icon;
  padding: 8px 18px 6px 18px;
  border: solid 3px $action;
  font-weight: bold;
  background-position: 20px 8px;
  &:hover{
    border: solid 3px $action;
    background-color: $white;
  }
}

@mixin button-secondary-icon-low {
  @include button-secondary-icon;
  background-image: url(./../assets/icons/low.svg);
}
@mixin button-secondary-icon-medium {
  @include button-secondary-icon;
  background-image: url(./../assets/icons/medium.svg);
}
@mixin button-secondary-icon-high {
  @include button-secondary-icon;
  background-image: url(./../assets/icons/high.svg);
}

@mixin button-secondary-icon-back {
  @include button-secondary-icon;
  background-image: url(./../assets/icons/back-arrow.png);
  background-position: 8px 11px;
  background-size: 18px;
}


@mixin button-progress-thin {
  margin: 0 4px;
  min-height: 3px;
  border-radius: 1.5px;
  background-color: $gray;
  &-number {
    display: none;
  }
  &-name {
    display: none;
  }
}
@mixin button-progress-thin-current {
  @include button-progress-thin;	
  background-color: $primary;
}

@mixin button-progress {
  position: relative;
  &-number {
    display: block;
    background: linear-gradient(to bottom, 
      $backgroundAlt calc(50% - 1px),
      $primary calc(50% - 1px) calc(50% + 1px), 
      $backgroundAlt calc(50% + 1px));
    > span {
      background-color: $white;
      width: 56px;
      height: 56px;
      border-radius: 28px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      border: 2px solid $primary; 
      font-size: 20px;
      font-weight: 800;
      color: #a9b2b5;
      transition: background-color .15s ease-in-out;
      &:hover {
        background-color: $action;
        color: $white;
        cursor: pointer;
      }
      > span {
        border: 2px solid $white;
        border-radius: 26px;
        display: block;
        width: 52px;
        height: 52px;
        line-height: 52px;
      }
    }
  }
  &:last-child {
    flex-grow: 0;
    max-width: 56px;
  }
  &-name {
    display: block;
    margin-top: 8px;
    text-align: center;
    width: 126px;
    position: relative;
    left: -34px;
    font-family: Hind;
    font-size: 14px;
    font-weight: 500;
    line-height: 0.93;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #006a4d;
  }
}

@mixin button-progress-current {
  @include  button-progress;
  > span {
    background-color: $action;
    color: $white;
    cursor: default;
  }
}
@mixin button-progress-done {
  @include button-progress;
  > span {
    background: $primary url(./../assets/icons/done.svg) center center no-repeat;
    color: $white;
    cursor: default;
    > span { display: none; }
  }
}

@mixin button-close {
  display: block;
  width: 24px;
  height: 24px;
  padding: 0;
  background: url(./../assets/icons/close.svg) center center no-repeat;
  border: none;
  > span {
    display: none;
  }
}

@mixin button-close-white {
  @include button-close;
  background: url(./../assets/icons/close-white.svg) center center no-repeat;
}

@mixin form-control {
  display: block;
  width: 100%;
  height: 44px;
  padding: 0 10px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: $placeholder;
  background-color:$white;
  background-clip: padding-box;
  border: none;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &::placeholder {
    color: $placeholder;
    font-size: 16px;
  }
}

@mixin form-textarea {
  display: block;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: $placeholder;
  background-color:$white;
  background-clip: padding-box;
  border: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &::placeholder {
    color: $placeholder;
    font-size: 16px;
  }
}

@mixin form-elements-1 {  
  background-color: white;
  border: thin solid #ccc;
  border-radius: 4px;
  display: inline-block;
  font: inherit;
  line-height: 1.5em;
  padding: 0.5em 3.5em 0.5em 1em;
  margin: 0;      
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;  
  &:focus {
    border-color: #666;
    outline: 0;
  }
}
@mixin select {
  @include form-elements-1;
  background-image:
  linear-gradient(45deg, transparent 50%, gray 50%),
  linear-gradient(135deg, gray 50%, transparent 50%);
  background-position:
    calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px);
  background-size:
    5px 5px,
    5px 5px;
  background-repeat: no-repeat;
  &:focus {
    background-image:
      linear-gradient(45deg, #666 50%, transparent 50%),
      linear-gradient(135deg, transparent 50%, green 50%);
    background-position:
      calc(100% - 15px) 1em,
      calc(100% - 20px) 1em;
    background-size:
      5px 5px,
      5px 5px;
    background-repeat: no-repeat;
  }
  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
  }
}

@mixin textarea {
  @include form-elements-1;
}

@mixin button-remove-outline {
  button.active.focus,
  button.active:focus,
  button.focus,
  button.focus:active,
  button:active:focus,
  button:focus {
    outline:none;
  }
}

@mixin style-checkbox {
  &__checkbox {
    margin-left: -5px;
    opacity: 0;
  }
  &__checkbox[type="checkbox"] + span:before {
      border: 2px solid #026a4c;
      background-color: #fff;
      border-radius: 3px;
      content: "\00a0";
      display: inline-block;
      font: 16px/1em sans-serif;
      height: 20px;
      margin: 2px 0.25em 0 0;
      padding: 0px;
      vertical-align: text-top;
      width: 20px;
      cursor: pointer;
  }
  &__checkbox[type="checkbox"]:checked + span:before {
      border: 2px solid #00ad4d;
      background: #00ad4d;
      color: #fff;
      content: "\f00c";
      text-align: center;
      font-family: fontawesome;
      cursor: pointer;
  }
}

/*Responsive Media Query for max-wdith*/

@mixin lgScreen {
  @media only screen and (max-width: 1199px) {
      @content;
  }
}

@mixin for-tablet-landscape-up {
  @media (min-width: 992px) {
      @content;
  }
}

@mixin mdScreen {
  @media only screen and (max-width: 991px) {
      @content;
  }
}

@mixin smScreen {
  @media only screen and (max-width: 767px) {
      @content;
  }
}

@mixin smsScreen {
  @media only screen and (max-width: 575px) {
      @content;
  }
}

@mixin xsScreen {
  @media only screen and (max-width: 480px) {
      @content;
  }
}

@mixin transition($transVal...) {
  -webkit-transition: $transVal;
  -moz-transition: $transVal;
  -ms-transition: $transVal;
  -o-transition: $transVal;
  transition: $transVal;
}

@mixin borderRadius($radiusVal...) {
  border-radius: $radiusVal;
  -moz-border-radius: $radiusVal;
  -webkit-border-radius: $radiusVal;
}

@mixin boxShadow($shadowVal...) {
  box-shadow: $shadowVal;
  -moz-box-shadow: $shadowVal;
  -webkit-box-shadow: $shadowVal;
}


@mixin transform($transVal...) {
  -webkit-transform: $transVal;
  -moz-transform: $transVal;
  -ms-transform: $transVal;
  -o-transform: $transVal;
  transform: $transVal;
}

@mixin mdScreen {
  @media only screen and (max-width: 991px) {
      @content;
  }
}

@mixin smScreen {
  @media only screen and (max-width: 767px) {
      @content;
  }
}

@mixin smsScreen {
  @media only screen and (max-width: 575px) {
      @content;
  }
}

@mixin xsScreen {
  @media only screen and (max-width: 480px) {
      @content;
  }
}
