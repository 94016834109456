@import './colors.scss';
@import './mixins.scss';
@import './pipeline.scss';

// Your variable overrides
$body-bg: $white;
$body-color: $black;

@font-face {
  font-family: 'Calibre-R';
  src: url('../styles/fonts/calibre-regular.woff2') format('woff2'),
    url('../styles/fonts/calibre-regular.woff') format('woff');
}

$font-family-sans-serif: 'Calibre-R', Arial, sans-serif !default;
$font-family-serif: 'Calibre-R', Arial, sans-serif !default;
$font-family-monospace: 'Calibre-R', Arial, sans-serif !default;
$font-family-base: $font-family-sans-serif !default;

$theme-colors: (
  'primary': $watercourse,
  'danger': $cinnabar,
  'dark': $watercourse
);

// Required
@import '../../node_modules/bootstrap/scss/bootstrap';

h1 {
  color: $watercourse;
  font-size: 1.2rem;
  font-weight: bold;
}

h2 {
  color: $watercourse;
  font-size: 20px;
  font-weight: bold;
}

h3 {
  color: $watercourse;
  font-size: 20px;
}

.top-buffer {
  margin-top: 20px;
}

/* add this class to containing div to avoid iPhone issues where  it
is impossible to scroll the page to the bottom of the viewport */
.bottom-margin-100 {
  margin-bottom: 100px;
}
.float-left {
  float: left;
}
.text-green {
  color: #006a4d !important;
}

.top_Title {
  background: #fff;
  padding: 5px 20px 5px 15px;
  border-bottom: solid 1px #cad0d8;
  border-radius: 5px 5px 0 0;
  position: relative;
  margin-top: 30px;
}
.link_disabled button {
  pointer-events: none !important;
  opacity: 0.6;
}

.link_disabled input,
.link_disabled textarea,
.link_disabled select,
.link_disabled .droppableDiv,
.link_disabled svg,
.link_disabled .uploader,
.link_disabled .uploader,
.link_disabled a {
  pointer-events: none !important;
  opacity: 0.6;
  background-color: #eee;
}
.priority-block {
  background: transparent;
  padding: 5px 0;
}

.top_Title {
  background: #fff;
  padding: 5px 20px 5px 0;
  border-bottom: solid 1px #cad0d8;
  border-top: solid 1px #cad0d8;
  border-radius: 0;
  position: relative;
  margin-top: 30px;
}

/*common styles*/
@include mdScreen {
  .green-tr {
    tr {
      background: map-get($colors, Green03) !important;
    }
  }
  .table_responsive {
    width: 100%;
    display: block;
    overflow-x: hidden !important;
    thead,
    tbody,
    th,
    td,
    tr {
      display: block;
    }
    thead {
      tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
      }
    }
    tr {
      border-bottom: solid 1px #cad0d8;
      background: transparent;
      margin: 15px 2px;
      @include boxShadow(0px 0px 3px rgba(0, 0, 0, 0.16));
      padding: 5px 0px;
      &:nth-child(odd) {
        background-color: #e8ecf3;
      }
      td {
        border: none !important;
        position: relative;
        padding-left: 50% !important;
        background-color: transparent !important;
        margin: 5px 0;
        padding-top: 5px !important;
        padding-bottom: 5px !important;
        &:before {
          position: absolute;
          top: 6px;
          left: 10px;
          width: 45%;
          padding-right: 10px;
          content: attr(data-column);
          font-weight: 700;
        }
        span {
          float: left !important;
          width: auto;
        }
      }
    }
  }
}

@include smsScreen {
  .table_responsive {
    tr {
      td {
        padding-left: 15px !important;
        &:before {
          font-weight: 700;
          position: relative;
          left: 0px;
          width: 100%;
          font-weight: 700;
          display: inline-block;
          margin-bottom: 5px;
        }
        span {
          float: inherit;
          width: auto;
        }
      }
    }
  }
}
